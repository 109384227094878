import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexDiv } from 'design-system/FlexDiv'
import { services } from '@services'
import { Link } from 'gatsby'

const TitleH1 = styled.h1`
  font-family: CentraNo2-Bold;
  font-size: 3em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased;
`

const SubHeader = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #000;
  -webkit-font-smoothing: antialiased;
`

const SubTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 1.75em;
  font-stretch: normal;
  font-style: normal;
  color: #000;
  -webkit-font-smoothing: antialiased;
`
const Label = styled.span`
  font-family: CentraNo2-Book;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: #000;
  -webkit-font-smoothing: antialiased;
`

const LandingInput = styled.input`
  background: transparent;
  border: 2px solid #adadad;
  font-family: CentraNo2-Book;
  -webkit-font-smoothing: antialiased;
  width: 80%;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: CentraNo2-Book;
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  color: #000;
  -webkit-font-smoothing: antialiased;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 50%;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`
export const Contact = () => {
  const [date, setDate] = useState<string>('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    underContract: 'no',
  })
  const [loading, setLoading] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newForm = {
      ...formData,
      [name]: value,
    }

    setFormData(newForm)
  }
  const submitForm = async () => {
    if (loading) {
      return
    }

    const body = {
      ...formData,
      date,
    }
    setLoading(true)
    const res = await services.lifted.sendHomeForm(body)
    if (res) {
      setLoading(false)
    }
  }
  return (
    <FlexDiv className="contactDetails">
      <FlexDiv style={{ flex: '0.6'}}>
        <h1> Get in touch with us </h1>
      </FlexDiv>
      <FlexDiv className="contactFooterInfo">
      <FlexDiv className="flexColumn">
        <h2> <span className="mobilePhoneSpan">Phone</span> Number</h2>
        <p> <a href="tel:416-203-7447" style={{ textDecoration: 'none', color: 'inherit'}}>(416) 203-7447</a></p>
      </FlexDiv>
      <FlexDiv className="flexColumn">
        <h2> Email </h2>
        <p> <a href="mailto:hello@ellicottrealty.ca" style={{ textDecoration: 'none', color: 'inherit'}}>hello@ellicottrealty.ca</a></p>
      </FlexDiv>
      <FlexDiv className="flexColumn">
        <h2> Address</h2>
          <p> <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit'}}> 10184 Yonge Street, Richmond Hill</Link></p>
        </FlexDiv>
        </FlexDiv>
    </FlexDiv>
  )
}
