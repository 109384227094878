import React from "react"
import styled from "styled-components"
import { FlexDiv } from "../design-system/FlexDiv"
// import blogPostImg from '../images/blogPostImg3.jpg'
import { Link } from "gatsby"
// import blogPostImg5 from '../images/blogPostImg5.jpg'
import blogPostImg3 from "../images/blogPostImg3.jpg"
import blogPostImg22 from "../images/blogPostImg.jpg"
import blogPostImg6 from "../images/pandemicera.jpg"

const BlogDetails = styled.p`
  color: gray;
`

const BlogTitle = styled.p`
  font-size: 1.25em;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  margin-right: 4em !important;
`
const blogExcerpt = styled.p``

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 0;
`

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const GivesBackWrapper = styled.div`
  margin: auto;
  padding: 5em;
  display: inline-flex;
`

const GivesBackTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  margin-bottom: 0.5em;
`

const GivesBackText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1.15em;
  line-height: 1.75em;
  font-style: italic;
`
const GivesBackText2 = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  line-height: 1.25em;
`

const ButtonLearnMore = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
`

const ButtonLearnMore2 = styled.button`
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  border: 1.5px solid #000;
`

const BackSearchLink = styled.a`
  font-family: CentraNo2-Book;
  font-weight: 500;
  margin: 1em 0 2em;
  cursor: pointer;
`

export const AchieveMoreSmartGoals: React.FunctionComponent = () => {
  return (
    <GivesBackWrapper className="blogPostWrapper">
      <FlexDiv style={{maxWidth: '75%', margin: 'auto'}} className="blogPostContainer">
        <FlexDiv
          style={{
            flexFlow: "column",
            maxWidth: "65%",
            marginRight: "7.5%",
            flex: "100",
          }}
          className="blogInner"
        >
          <GivesBackTitle className="blogTitle">
            Achieve More By Setting SMART Goals
          </GivesBackTitle>
          <GivesBackText2 style={{ margin: 0 }} className="dateText">
            Jan 27, 2020, 08:00am EST
          </GivesBackText2>

          <GivesBackText className="authorBio">
            Anthony Tropea, President & Co-Founder of Ellicott Realty Group,
            overseeing the company's portfolio: Ellicott Realty, Ellicott
            Financial, & MacKenzie Hall.
          </GivesBackText>
          <img
            src={blogPostImg3}
            style={{
              width: "100%",
              margin: "auto",
              padding: "1em 0",
            }}
          />

          <GivesBackText2>
            Reflecting on the year that just passed, business leaders must
            examine how productively our time was spent and identify the
            situations where we can improve, both professionally and personally.
            It’s that time when we consider plans for the year ahead and the
            challenges the year may bring. It means time to set your goals for
            the forthcoming campaign of your life and implement a plan for
            accomplishing a better version of yourself.
          </GivesBackText2>

          <GivesBackText2>
            <strong> SMART Goals Work Best</strong>
          </GivesBackText2>

          <GivesBackText2>
            You must be realistic when setting goals, because discouragement can
            arise if you set unachievable targets, resulting in higher
            likelihoods of failure and diminished long-term confidence. When
            creating any goal, a common acronym to use as a framework is SMART,
            which stands for specific, measurable, achievable, relevant and
            time-bound. Easy to remember, the SMART process for creating goals
            can help real estate professionals achieve success in all sorts of
            challenges. As a business owner, evaluate these key facets of your
            life when creating goals this year.
          </GivesBackText2>

          <GivesBackText2>
            <strong> 1. Professional Life</strong>
          </GivesBackText2>

          <GivesBackText2>
            Evaluate your professional life from various angles. How can we make
            the company better? How can we advance our employees/colleagues?
            Spend time reflecting on the previous year, and see how you can
            improve in the year ahead. Eliminate to-do items that you tested and
            proved to be unprofitable or useless. Also look at new advances and
            technologies that you expect to improve your business approach.
            Strive to implement items that will allow you to overcome existing
            challenges deemed unattainable in the past; this is a concrete path
            for sustained growth.
          </GivesBackText2>

          <GivesBackText2>
            Continually track employees’ and team members’ goal progress from
            the previous year to see if they are on pace to reach their
            achievements. If not, what can you, as a leader, do to ensure that
            goals are achieved for the coming year? If the goals were met, work
            together to create new goals for improvement.
          </GivesBackText2>

          <GivesBackText2>
            <strong> 2. Personal Life</strong>
          </GivesBackText2>

          <GivesBackText2>
            When reviewing your personal goals, work will likely be an element,
            but mainly, you may want to look at relationships with clients,
            customers, family, friends, colleagues and employees, and consider
            your everyday life experiences as well. Find where you need to make
            adjustments to become the best version of yourself for them. Focus
            on the main components of your life, such as health, finances and
            growth.
          </GivesBackText2>

          <GivesBackText2>
            When you know the “why” behind your goals, you can better understand
            how personal goals in relationships, health and personal growth
            serve as the reasons we make financial, career and similar goals.
            The “whys” deserve the most attention because they readily affect
            our work life and our success. For example, keeping yourself healthy
            may result in increased focus and energy levels, allowing both mind
            and body to thrive, especially during long workdays or business
            trips. A healthy lifestyle often leads to clearer thought processes
            that encourage solutions and ideas, making you feel confident in
            taking on any challenges that help you or your business improve.
            Strengthening work relationships with employees is another example
            of a personal goal that can advance the success of your brokerage.
            As you prioritize employees, they prioritize their productivity for
            you.
          </GivesBackText2>

          <GivesBackText2>
            <strong>3. Charity/Community</strong>
          </GivesBackText2>
          <GivesBackText2>
            I believe success in its truest form is enhanced by fortifying those
            less fortunate than yourself, so I set an annual goal to try to
            benefit certain charities, people and causes in my community.
            Recently, our company partnered with a nonprofit organization to
            create educational programming for youth. An expression of gratitude
            and the relative appreciation of a person's most-valued possessions
            can catapult you into a new echelon of humility. Different mindsets
            and plans of action can be tailored to your individual style;
            however, as Antoine de Saint-Exupéry said, “A goal without a plan is
            just a wish.”{" "}
          </GivesBackText2>
          <GivesBackText2>
            Through charitable endeavors, we can all be involved in the
            community, give back in a meaningful manner and create impact. You
            might start by considering charitable opportunities that mean
            something to your brokerage firm. Do you, your employees or a client
            have personal experience with a specific charity or organization to
            which the company could contribute in some meaningful way? Is there
            an ongoing need in your community or in the neighborhood where your
            business is located? You might consider service opportunities
            related to housing since you are in the real estate business, for
            example. If you and your employees become more vested in the
            opportunity, then it makes an impact — perhaps a newsworthy one, as
            well. Publicity, although it may be a residual benefit, shouldn’t be
            the primary reason for serving the community,
          </GivesBackText2>
          <GivesBackText2>
            The public generally responds positively to altruism they see in
            companies. You’ll discover new clients who want you to represent
            them. You’ll find dedicated associates drawn to your workplace,
            wanting to be part of your team. Goals designed to contribute to a
            charity or a community warrant brainstorming with your team. Sincere
            service is a win-win, as it brings joy to those who receive your
            kindness and to your team as they give of themselves.
          </GivesBackText2>
          <GivesBackText2>
            Set a few SMART goals, and bring along your whole team for the ride
            toward a year of potential and progress. Here’s to change, new
            challenges and creating community.
          </GivesBackText2>
        </FlexDiv>
         <FlexDiv style={{ display: "flex", flexDirection: "column" }}>
          <FlexDiv
            style={{
              flex: "0.8",
              flexFlow: "column",
              paddingTop: "285px",
              marginLeft: "5%",
            }}
            className="relatedBlogPosts achieveSideBar"
          >
            <h2 className="mobileOnly">
            Continue Reading
          </h2>
            <div
              style={{ background: "#fff", maxWidth: "90%" }}
              className="socialCard"
            >
              <Link
                to="/pandemicera"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontFamily: "CentraNo2-Medium",
                }}
                 className="imgLinkWrapper"
              >
                <img
                  src={blogPostImg6}
                  style={{ width: "100%" }}
                  className="socialImg"
                />
              </Link>
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                          className="relatedBlogPostsTitle"
                        >
                          The Pandemic-Era Trends Impacting Real Estate
                          Profitability
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          Whether you're bullish or bearish on the financial
                          impacts of Covid-19, this is undoubtedly a time to
                          profit on real estate if you know how to navigate the
                          market.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                        className="readArticleForbes"
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2021/12/28/the-pandemic-era-trends-impacting-real-estate-profitability/?sh=79d1c5a68d4a"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/propmanagement"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg22}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          How Property Management Can Make It Through A Pandemic
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          In this time of great hardship, let us be mindful that
                          we are indeed in this together, and commit to treating
                          one another with the respect that all humankind
                          deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                        className="readArticleForbes"
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2020/07/13/how-property-management-can-make-it-through-a-pandemic/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/truecolours"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg3}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          It Is Time For Our True Colors To Illustrate True
                          Leadership And Connection
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          As a business owner, evaluate these key facets of your
                          life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                        className="readArticleForbes"
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/05/28/it-is-time-for-our-true-colors-to-illustrate-true-leadership-and-connection/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </GivesBackWrapper>
  )
}
