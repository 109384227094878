import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { AchieveMoreSmartGoals } from 'components/AchieveMoreSmartGoals'
import { Contact } from 'components/Contact'

const AchieveSmartGoals: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <AchieveMoreSmartGoals />
      <Contact />
    </EllicotLayout>
  )
}

export default AchieveSmartGoals
